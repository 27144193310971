export enum Challenges {
  enableVisibilityByName = 'specs.challenges.OOIEnableVisibilityByName',
  enableAutopilot = 'specs.challenges.BMEnableAutopilot',
  enableAutopilotADI = 'specs.challenges.BMEnableAutopilotADI',
  enableAutopilotEditorX = 'specs.challenges.BMEnableAutopilotEditorX',
  enableDripContent = 'specs.challenges.WebMemberDripContent',
  enableMyProgramsMA = 'specs.challenges.OOIMyProgramsMA',
  showPricingPageInMenu = 'specs.viewer-apps-2936472a-a1ed-4ae5-9f71-614313a9f4e7.OOIShowPricingPageInMenu',
  enableVisitorPageRedesignV1 = 'specs.viewer-apps-2936472a-a1ed-4ae5-9f71-614313a9f4e7.OOIVisitorRedesignV1Users',
  enableProgramVisitorPageV2 = 'specs.programs.OOIVisitorRedesignV2',
  enableProgramVisitorPageV3 = 'specs.programs.OOIVisitorRedesignV3',
  skipMAInstallation = 'specs.programs.OOISkipMAInstallationUsers',
  programCardsAlignment = 'specs.viewer-apps-2936472a-a1ed-4ae5-9f71-614313a9f4e7.OOIProgramCardsAlignment',
}
